export const socialMedia = {
    bsky:{
        "name": "bsky",
        "svg": '<svg width="100" height="100" viewBox="0 -50 650 600" aria-hidden="true"><path class="social-media-svg" fill="#bfa181" d="M123.121 33.664C188.241 82.553 258.281 181.68 284 234.873c25.719-53.192 95.759-152.32 160.879-201.21C491.866-1.611 568-28.906 568 57.947c0 17.346-9.945 145.713-15.778 166.555-20.275 72.453-94.155 90.933-159.875 79.748C507.222 323.8 536.444 388.56 473.333 453.32c-119.86 122.992-172.272-30.859-185.702-70.281-2.462-7.227-3.614-10.608-3.631-7.733-.017-2.875-1.169.506-3.631 7.733-13.43 39.422-65.842 193.273-185.702 70.281-63.111-64.76-33.89-129.52 80.986-149.071-65.72 11.185-139.6-7.295-159.875-79.748C9.945 203.659 0 75.291 0 57.946 0-28.906 76.135-1.612 123.121 33.664Z"/></svg>',
        "url": "https://bsky.app/profile/"
    },
    furaffinity:{
        "name": "furaffinity",
        "svg": `<svg width="100" height="100" viewBox="0 0 36.8 38.3" aria-hidden="true">
                <path class="social-media-svg" fill="#bfa181" d="M15 38v-1l1-4-2-3v2l-1 1-1-1 1-2-1-1h-2l-3 2-2 3-1 1-2 1v-2l5-5 1-1v-2l-1-1-1-1-1 1H2l1-2h3l1-1-1-1H2l-1-1H0l2-1h4l1-2v-5h2v-1L5 8 4 7V6l1-1h1l3 3 1 1 1 1h1c1 0 0 0 0 0l1-1V8l-1-3V3l2 2v2h1V6l-1-3-1-2h1v1l3 5h1c2 0 1-1 1-2V1l1-1v4c1 2 1 2 2 1l2-1 1 1v2l1 2h1l1 1h1l1-2h1v1l-1 1v2l2 1v2l1 1v4l-1 2v3h1v1h-2v1h1v1h-1l-1-1v1l1 1v1h-1l-1-1-1-1v1l-1 1c0-1 0 0 0 0l1 3 1 2v2h-1l-1-1-1-4-1-1-2-1v5l-1-1v-1l-2-3-2 2-1 4-1 2h-1zm-4-14c0 2 1 3 2 3h3l2 1 3 1h2c1-2 2-4 0-6l-1-2c-1-2-2-3-4-3l-2 2-2 1c-2 0-3 1-3 3zm10-13-1-2h-2c-2 1-3 2-3 4-1 1 0 3 1 3 2 1 3 1 4-1l1-4zm5 3v-3c-1-1-2-2-3 0-2 1-2 3-2 5l2 2 2-1 1-3zm-14 6c2 0 3-1 3-2l-1-3-2-1-2 2v3l2 1zm16 2v-3l-2-1-2 2v3c1 1 2 2 3 1l1-2zm-15-9v-1l-1 1h1zm11-5v1h1l-1-1zm4 9s-1 0 0 0v1-1zM21 7l-1 1 1-1zM0 31h1-1zM35 17h-2 1l2-1 1 1h-2z"/>
                <path class="social-media-svg" fill="#bfa181" d="m31 33 1 2h-1l-1-1 1-1zM3 12l1 1-1 1v-2zM6 28H5l1-1h1l-1 1zM24 3l-1 1-1-1h2zM34 27l1 1h-1v-1zM31 7l1-1v1h-1zM19 34v-1 1zM33 12h-1 1zM5 12v-1 1zM34 12z"/>
                </svg>`,
        "url": "https://furaffinity.net/user/"
    },
    instagram:{
        "name": "instagram",
        "svg": '<svg width="100" height="100" viewBox="0 0 24 24" aria-hidden="true"><path class="social-media-svg" fill="#bfa181" d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"/></svg>',
        "url": "https://instagram.com/"
    },
    telegram:{
        "name": "telegram",
        "svg": '<svg width="800px" height="800px" viewBox="0 0 24 24" aria-hidden="true"><path class="social-media-svg" fill="#bfa181" d="M23.91 3.79L20.3 20.84c-.25 1.21-.98 1.5-2 .94l-5.5-4.07-2.66 2.57c-.3.3-.55.56-1.1.56-.72 0-.6-.27-.84-.95L6.3 13.7l-5.45-1.7c-1.18-.35-1.19-1.16.26-1.75l21.26-8.2c.97-.43 1.9.24 1.53 1.73z"/></svg>',
        "url": "https://t.me/"
    },
    tumblr:{
        "name": "tumblr",
        "svg": '<svg width="100" height="100" viewBox="0 0 24 24" aria-hidden="true"><path class="social-media-svg" fill="#bfa181" d="M14.563 24c-5.093 0-7.031-3.756-7.031-6.411V9.747H5.116V6.648c3.63-1.313 4.512-4.596 4.71-6.469C9.84.051 9.941 0 9.999 0h3.517v6.114h4.801v3.633h-4.82v7.47c.016 1.001.375 2.371 2.207 2.371h.09c.631-.02 1.486-.205 1.936-.419l1.156 3.425c-.436.636-2.4 1.374-4.156 1.404h-.178l.011.002z"/></svg>',
        "url": "https://www.tumblr.com/"
    },
    twitter:{
        "name": "twitter",
        "svg": '<svg width="100" height="100" viewBox="0 0 24 24" aria-hidden="true"><path class="social-media-svg" fill="#bfa181" d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z"/></svg>',
        "url": "https://x.com/"
    },
    
}