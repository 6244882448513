<template>
  <div :id="galleryID" class="gallery">
    <a
      v-for="(image, index) in imagesData"
      :key="image.id"
      :href="image.largeURL"
      :data-pswp-width="image.width"
      :data-pswp-height="image.height"
      target="_blank"
      rel="noreferrer"
    >
      <div class="thumbnail-container">
        <img
          class="thumbnail-img"
          :src="image.thumbnailURL"
          :style="{ animationDelay: `${index / 10}s` }"
          alt=""
        />
        <div class="overlay">
          {{ image.artist.name }} {{ image.creationDate }}
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";
import { socialMedia } from "@/assets/social_media";

// Swipe arrow SVG
const leftArrowSVGString = `<svg class="pswp__icn" height="800px" width="800px" viewBox="0 0 55.752 55.752">
	<path class="arrow-svg" d="M12.745,23.915c0.283-0.282,0.59-0.52,0.913-0.727L35.266,1.581c2.108-2.107,5.528-2.108,7.637,0.001
		c2.109,2.108,2.109,5.527,0,7.637L24.294,27.828l18.705,18.706c2.109,2.108,2.109,5.526,0,7.637
		c-1.055,1.056-2.438,1.582-3.818,1.582s-2.764-0.526-3.818-1.582L13.658,32.464c-0.323-0.207-0.632-0.445-0.913-0.727
		c-1.078-1.078-1.598-2.498-1.572-3.911C11.147,26.413,11.667,24.994,12.745,23.915z"/>
</svg>`;

export default {
  name: "SimpleGallery",
  props: {
    galleryID: String,
    images: Array,
  },
  setup(props) {
    return {
      imagesData: props.images,
    };
  },
  mounted() {
    this.imagesData.sort(
      (a, b) => new Date(b.creationDate) - new Date(a.creationDate)
    );

    if (!this.lightbox) {
      this.lightbox = new PhotoSwipeLightbox({
        arrowPrevSVG: leftArrowSVGString,
        arrowNextSVG: leftArrowSVGString,
        gallery: "#" + this.$props.galleryID,
        children: "a",
        pswpModule: () => import("photoswipe"),
        zoom: false, // Enable zoom button
        counter: false, // Enable counter
        bgOpacity: 0.9, // Set background opacity
        loop: false,
        wheelToZoom: true,
        allowPanToNext: false,
        padding: { top: 60, bottom: 0, left: 0, right: 0 },
        mainClass: "pswp--custom-icon-colors", // Apply custom styles to this instance
      });
      this.lightbox.on("change", () => {
        // triggers when slide is switched, and at initialization
        unregisterArtistName();
        unregisterSocialMediaButtons();
        registerSocialMediaButtons(this.lightbox);
      });
      this.lightbox.init();
    }

    const registerSocialMediaButtons = (lightbox) => {
      const currentImageData = this.imagesData[lightbox.pswp.potentialIndex];

      if (currentImageData && currentImageData.artist) {
        registerArtistName(lightbox, currentImageData.artist.name);
        for (let artistSM of this.imagesData[lightbox.pswp.potentialIndex]
          .artist.socialMedia) {
          lightbox.pswp.ui.registerElement({
            name: "social-media-button",
            ariaLabel: "Social media",
            order: 9,
            isButton: true,
            html: getSvg(Object.keys(artistSM)[0]),
            onClick: () => {
              window.open(Object.values(artistSM)[0], "_blank");
            },
          });
        }
      }
    };

    function registerArtistName(lightbox, artistName) {
      lightbox.pswp.ui.registerElement({
        name: "artist-name",
        ariaLabel: "${artistName}",
        order: 1,
        onInit: (el) => {
          el.innerText = "By " + artistName + ":";
        },
      });
    }

    function getSvg(socialMediaName) {
      return socialMedia[socialMediaName]?.svg;
    }

    function unregisterSocialMediaButtons() {
      // Find the container that holds the buttons
      const pswpContainer = document.querySelector(".pswp"); // Adjust if necessary

      if (pswpContainer) {
        // Find all elements with the class corresponding to the name
        const elements = pswpContainer.querySelectorAll(
          `.pswp__button--social-media-button`
        );

        if (elements.length > 0) {
          elements.forEach((element) => {
            element.remove(); // Removes each found element from the DOM
          });
        }
      }
    }

    function unregisterArtistName() {
      // Find the container that holds the buttons
      const pswpContainer = document.querySelector(".pswp"); // Adjust if necessary

      if (pswpContainer) {
        // Find all elements with the class corresponding to the name
        const elements = pswpContainer.querySelectorAll(`.pswp__artist-name`);

        if (elements.length > 0) {
          elements.forEach((element) => {
            element.remove(); // Removes each found element from the DOM
          });
        }
      }
    }
  },
  unmounted() {
    if (this.lightbox) {
      this.lightbox.destroy();
      this.lightbox = null;
    }
  },
  methods: {},
};
</script>

<style lang="less">
@gold: #bfa181;

button.pswp__button {
  display: flex; /* Enable Flexbox */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
}

button.pswp__button--social-media-button svg {
  width: 75%; /* Scale SVG to fit within button */
  height: 75%; /* Maintain aspect ratio */
}

.social-media-svg {
  transform-box: fill-box;
  transform-origin: center center;
  transform: scale(0.8);
}

.pswp__button--close {
  order: 99; /* Ensure the close button appears last */
}

.pswp--custom-icon-colors {
  --pswp-icon-color: @gold;
  --pswp-icon-color-secondary: #333;
}

.gallery a {
  margin-right: 10px;
  margin-bottom: 10px;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  align-items: center; /* Centra verticalmente */
  justify-content: center; /* Centra horizontalmente */
  width: 100%;
  height: 100%;
  z-index: 1000; /* Asegura que esté por encima de otros elementos */
  padding: 5%;
  padding-top: 1%;
}

.thumbnail-container {
  position: relative;
  width: 100%;
}

.pswp__top-bar {
  font-family: "Dragon Fire", sans-serif;
  color: @gold;
}

.pswp__artist-name {
  display: flex;
  font-size: 30px;
  align-items: center; /* Centra verticalmente */
  justify-content: center; /* Centra horizontalmente */
  padding-right: 10px;
}

.thumbnail-img {
  opacity: 0;
  animation: fadeIn 2s forwards;
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: @gold;
  width: 100%;
  transition: 0.5s ease;
  opacity: 0;
  font-size: 20px;
  padding: 20px;
  text-align: center;
}

.thumbnail-container:hover .overlay {
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 600px) {
  .thumbnail-container {
    width: 100%; /* Asegura que el contenedor se ajuste al 100% del ancho de la pantalla */
    overflow: hidden; /* Evita que el contenido desborde */
  }

  .thumbnail-img {
    width: 100%; /* Ajusta el ancho de la imagen al contenedor */
    height: auto; /* Mantiene la proporción de la imagen */
    object-fit: cover; /* Cubre el área del contenedor sin distorsionarse */
  }

  .pswp__artist-name {
    display: flex;
    font-size: 20px;
    align-items: center; /* Centra verticalmente */
    justify-content: center; /* Centra horizontalmente */
    padding-right: 10px;
  }

  .pswp__button--social-media-button {
    width: 35px;
  }

  .arrow-svg {
    visibility: hidden;
  }
}
</style>