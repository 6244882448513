<template>
  <div class="gallery">
    <Gallery galleryID="gallery" :images="images"></Gallery>
  </div>
</template>

<script>
// @ is an alias to /src
import Gallery from "@/components/Gallery.vue";
import { images } from "@/assets/images_sfw.js";

export default {
  name: "SFWGalleryView",
  components: {
    Gallery,
  },
  data() {
    return {
      images,
    };
  },
};
</script>