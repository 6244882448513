<template>
  <div class="media-links-bar">
    <a
      v-for="sm in socialMediaData"
      :key="sm.id"
      :href="sm.url"
      target="_blank"
      rel="noopener noreferrer"
    >
      <button
        class="pswp__button pswp__button--social-media-button"
        v-html="sm.svg"
      ></button>
    </a>
  </div>
</template>
    
<script>
export default {
  name: "MediaLinksBar",
  props: {
    socialMedias: Object,
  },
  setup(props) {
    return {
      socialMediaData: props.socialMedias,
    };
  },
};
</script>

<style lang="less">
.media-links-bar {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.media-links-bar button svg {
  width: 60px !important;
}
</style>