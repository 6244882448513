<template>
  <h1 class="main-title">Limo's Lair</h1>
  <media-links-bar :socialMedias="socialMedias"></media-links-bar>
  <nav>
    <v-tabs class="tabs" align-tabs="center" :direction="direction">
      <router-link to="/"><v-tab :value="1">SFW</v-tab></router-link>
      <router-link to="/nsfw"><v-tab :value="2">NSFW</v-tab></router-link>
      <router-link to="/stickers"
        ><v-tab :value="3">Stickers</v-tab></router-link
      >
      <router-link to="/contact"><v-tab :value="4">Contact</v-tab></router-link>
    </v-tabs>
  </nav>
  <router-view />
</template>

<script>
import { computed } from "vue";
import { useDisplay } from "vuetify";
import MediaLinksBar from "./components/MediaLinksBar.vue";
import { socialMedia } from "./assets/social_media";

export default {
  components: { MediaLinksBar },
  data() {
    return {
      socialMedias: {},
    };
  },
  setup() {
    const { name } = useDisplay();

    const direction = computed(() => {
      switch (name.value) {
        case "xs":
          return "vertical";
        case "sm":
        case "md":
        case "lg":
        case "xl":
        case "xxl":
          return "horizontal";
        default:
          return undefined;
      }
    });

    return {
      direction,
    };
  },
  mounted() {
    document.title = "Limo's lair"; // Change the tab name here
    this.socialMedias.bsky = socialMedia.bsky;
    this.socialMedias.furaffinity = socialMedia.furaffinity;
    this.socialMedias.bsky.url += "limoslair.site";
    this.socialMedias.furaffinity.url += "limothedragon";
  },
};
</script>

<style lang="less">
@dark-blue: #0a1828;
@turqoise: #178582;
@gold: #bfa181;

@font-face {
  font-family: "Dragon Fire";
  src: url("@/assets/fonts/DragonFire.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

html {
  background: @dark-blue;
}

#app {
  font-family: "Dragon Fire", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 2rem;
}

.main-title {
  color: @gold;
  font-size: 120px;
  margin-top: 5%;
}

.tabs {
  color: @turqoise;
  /* Add vertical direction styles */
  &.vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .v-tab {
      width: 100%;
      text-align: center;
      padding: 10px 0;
    }
  }
}

.tabs .v-btn__content {
  font-size: 2rem;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #49627e;

    &.router-link-exact-active {
      color: @turqoise;
    }
  }
}

@media (max-width: 600px) {
  .main-title {
    color: @gold;
    font-size: 78px;
    margin-top: 5%;
  }

  .tabs {
    align-items: center;
  }

  .v-tabs--align-tabs-center .v-slide-group__content > :first-child {
    margin-inline-start: 0 !important; /* Reset the margin */
  }
}
</style>